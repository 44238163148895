import { gql } from '@apollo/client';
import apolloClient from '../Apollo';

const LIST_COMPETITIONS = gql`
  query($filters: JSON) {
    competitions(where: $filters) {
      id
      created_at
      updated_at
      name
      description
      price
      slug
      status
      start_registration_date
      end_registration_date
      logo {
        url
      }
      categories {
        name
        wods {
          id
          name
        }
      }
    }
  }
`;

export async function getCompetitions(filters) {
  try {
    const result = await apolloClient.query({
      query: LIST_COMPETITIONS,
      variables: { filters },
    });

    if (result.data !== null) {
      return result.data.competitions;
    }
    return [];
  } catch (error) {
    console.log('error', error);
    return [];
  }
}
