import { gql } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import apolloClient from 'Apollo';
import { LearnMoreLink } from 'components/atoms';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getError } from 'utils/Common';
import yup from 'yup.config';

const REGISTER_MUTATION = gql`
  mutation($username: String!, $email: String!, $password: String!) {
    register(input: { username: $username, email: $email, password: $password }) {
      jwt
      user {
        id
        firstname
        lastname
        username
        email
      }
    }
  }
`;

const schema = yup.object().shape({
  email: yup.string().email().max(300).required(),
  password: yup.string().min(6).required(),
  firstname: yup.string().required(),
  lastname: yup.string().required(),
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

const Form = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  let history = useHistory();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const registerUser = async ({ firstname, lastname, email, password }) => {
    try {
      const result = await apolloClient.mutate({
        variables: { firstname, lastname, username: '', email, password },
        mutation: REGISTER_MUTATION,
      });

      if (result.data !== null) {
        const { user } = result.data.register;
        const token = result.data.register.jwt;
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', token);

        return user;
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const onSubmit = async (data) => {
    let user = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      password: data.password,
    };
    user = await registerUser(user);
    if (user) {
      enqueueSnackbar(t('auth.messages.register_success'), {
        variant: 'success',
      });
      history.push('/');
    } else {
      enqueueSnackbar(t('auth.messages.register_error'), {
        variant: 'error',
      });
    }
  };

  const hasError = (field) => {
    if (errors[field]) {
      return true;
    }
    return false;
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)} method="post">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="firstname"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField placeholder={t('fields.firstname')} label={`${t('fields.firstname')} *`} variant="outlined" size="medium" fullWidth helperText={getError(errors.firstname)} error={hasError('firstname')} type="text" {...field} />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="lastname"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField placeholder={t('fields.lastname')} label={`${t('fields.lastname')} *`} variant="outlined" size="medium" fullWidth helperText={getError(errors.lastname)} error={hasError('lastname')} type="text" {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField placeholder={t('fields.email')} label={`${t('fields.email')} *`} variant="outlined" size="medium" fullWidth helperText={getError(errors.email)} error={hasError('email')} type="email" {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField placeholder={t('fields.password')} label={`${t('fields.password')} *`} variant="outlined" size="medium" fullWidth helperText={getError(errors.password)} error={hasError('password')} type="password" {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <i>
              <Typography variant="subtitle2">{t('auth.marked_fields_required')}</Typography>
            </i>
          </Grid>
          <Grid item xs={12}>
            <Button size="large" variant="contained" type="submit" color="primary" fullWidth>
              {t('auth.register')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textSecondary" align="center">
              {t('auth.already_have_account')} <LearnMoreLink title={t('auth.login')} href="/login" />
            </Typography>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Form;
