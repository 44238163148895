import { colors, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { LearnMoreLink } from 'components/atoms';
import { SwiperImage } from 'components/molecules';
import { CardProduct } from 'components/organisms';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCompetitions } from 'services/Competition';

const useStyles = makeStyles((theme) => ({
  swiperNavButton: {
    width: `${theme.spacing(3)} !important`,
    height: `${theme.spacing(3)} !important`,
    padding: `${theme.spacing(2)} !important`,
  },
  locationCardPrice: {
    padding: theme.spacing(1),
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    background: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    zIndex: 3,
  },
  fontWeight700: {
    fontWeight: 700,
  },
  locationCardReviewAvatar: {
    marginLeft: theme.spacing(-2),
    border: `3px solid ${theme.palette.background.paper}`,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  locationCardReviewStar: {
    color: colors.yellow[800],
    marginRight: theme.spacing(1 / 2),
  },
  reviewCount: {
    marginLeft: theme.spacing(1),
  },
  image: {
    borderBottomLeftRadius: '40%',
  },
}));

const CompetitionsList = (props) => {
  const { className, ...rest } = props;
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const [competitions, setCompetitions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const competitions = await getCompetitions();
      setCompetitions(competitions);
    }
    fetchData();
  }, []);

  return (
    <div className={className} {...rest}>
      <Grid item container spacing={2}>
        {competitions.map((item, index) => (
          <Grid item key={index} xs={12} sm={12} md={4} data-aos="fade-up">
            <CardProduct
              withShadow
              liftUp
              mediaContent={
                <>
                  <SwiperImage
                    navigationButtonStyle={classes.swiperNavButton}
                    items={[
                      {
                        src: 'https://images.unsplash.com/photo-1476480862126-209bfaa8edc8?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c3BvcnR8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
                        srcSet: 'https://assets.maccarianagency.com/the-front/photos/coworking/place1@2x.jpg 2x',
                        alt: '...',
                      },
                    ]}
                    imageClassName={classes.image}
                  />
                  <div className={classes.locationCardPrice}>
                    <Typography variant="body1" color="primary" className={classes.fontWeight700}>
                      {item.price} €
                    </Typography>
                  </div>
                </>
              }
              cardContent={
                <Grid item container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="textPrimary" align="left" className={classes.fontWeight700}>
                      {item.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" color="textSecondary" align="left">
                      adress
                    </Typography>
                  </Grid>
                  <Grid item container justifyContent="flex-end" xs={12}>
                    <LearnMoreLink title={t('views.competitions.more')} href={`competition/${item.slug}`} />
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

CompetitionsList.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default CompetitionsList;
