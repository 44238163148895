/**
 * Caution: Consider this file when using react-scripts
 *
 * You may delete this file and its occurrences from the project filesystem if you are using GatsbyJS or NextJS version
 */
import { gql, useReactiveVar } from '@apollo/client';
import { isLoggedInVar } from 'cache';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import WithLayout from 'WithLayout';
import { DocsLayout, Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import {
  About as AboutView,
  AboutSideCover as AboutSideCoverView,
  Account as AccountView,
  Agency as AgencyView,
  BlogArticle as BlogArticleView,
  BlogNewsroom as BlogNewsroomView,
  BlogReachView as BlogReachViewView,
  BlogSearch as BlogSearchView,
  CareerListingMinimal as CareerListingMinimalView,
  CloudHosting as CloudHostingView,
  CompanyTerms as CompanyTermsView,
  Competition as CompetitionView,
  Competitions as CompetitionsView,
  ContactPage as ContactPageView,
  ContactPageCover as ContactPageCoverView,
  ContactPageSidebarMap as ContactPageSidebarMapView,
  Coworking as CoworkingView,
  DesignCompany as DesignCompanyView,
  DesktopApp as DesktopAppView,
  Documentation as DocumentationView,
  Ecommerce as EcommerceView,
  Elearning as ElearningView,
  Enterprise as EnterpriseView,
  Expo as ExpoView,
  HelpCenter as HelpCenterView,
  HelpCenterArticle as HelpCenterArticleView,
  Home as HomeView,
  IndexView,
  JobListing as JobListingView,
  Login as LoginView,
  Logistics as LogisticsView,
  MobileApp as MobileAppView,
  NotFound as NotFoundView,
  PasswordResetCover as PasswordResetCoverView,
  PasswordResetSimple as PasswordResetSimpleView,
  PortfolioGrid as PortfolioGridView,
  PortfolioMasonry as PortfolioMasonryView,
  PortfolioPage as PortfolioPageView,
  Pricing as PricingView,
  Register as RegisterView,
  Rental as RentalView,
  Service as ServiceView,
  SigninSimple as SigninSimpleView,
  SignupSimple as SignupSimpleView,
  Startup as StartupView,
  WebBasic as WebBasicView,
} from './views';

const Routes = () => {
  return (
    <Switch>
      {/* Routes validées */}

      <Route exact path="/" render={(matchProps) => <WithLayout {...matchProps} component={IndexView} layout={MainLayout} />} />

      <GuestRoute exact path="/login" render={(matchProps) => <WithLayout {...matchProps} component={LoginView} layout={MinimalLayout} />} />

      <GuestRoute exact path="/register" render={(matchProps) => <WithLayout {...matchProps} component={RegisterView} layout={MinimalLayout} />} />

      <PrivateRoute exact path="/account" render={(matchProps) => <WithLayout {...matchProps} component={AccountView} layout={MainLayout} />} />

      <Route exact path="/competitions" render={(matchProps) => <WithLayout {...matchProps} component={CompetitionsView} layout={MainLayout} />} />
      <Route exact path="/competition/:slug" render={(matchProps) => <WithLayout {...matchProps} component={CompetitionView} layout={MainLayout} />} />

      {/* Routes du thème */}

      <Route exact path="/home" render={(matchProps) => <WithLayout {...matchProps} component={HomeView} layout={MainLayout} />} />
      <Route exact path="/career-listing-minimal" render={(matchProps) => <WithLayout {...matchProps} component={CareerListingMinimalView} layout={MainLayout} />} />
      <Route exact path="/contact-page" render={(matchProps) => <WithLayout {...matchProps} component={ContactPageView} layout={MainLayout} />} />
      <Route exact path="/coworking" render={(matchProps) => <WithLayout {...matchProps} component={CoworkingView} layout={MainLayout} />} />
      <Route exact path="/e-learning" render={(matchProps) => <WithLayout {...matchProps} component={ElearningView} layout={MainLayout} />} />
      <Route exact path="/enterprise" render={(matchProps) => <WithLayout {...matchProps} component={EnterpriseView} layout={MainLayout} />} />
      <Route exact path="/service" render={(matchProps) => <WithLayout {...matchProps} component={ServiceView} layout={MainLayout} />} />
      <Route exact path="/web-basic" render={(matchProps) => <WithLayout {...matchProps} component={WebBasicView} layout={MainLayout} />} />
      <Route exact path="/desktop-app" render={(matchProps) => <WithLayout {...matchProps} component={DesktopAppView} layout={MainLayout} />} />
      <Route exact path="/expo" render={(matchProps) => <WithLayout {...matchProps} component={ExpoView} layout={MainLayout} />} />
      <Route exact path="/agency" render={(matchProps) => <WithLayout {...matchProps} component={AgencyView} layout={MainLayout} />} />
      <Route exact path="/startup" render={(matchProps) => <WithLayout {...matchProps} component={StartupView} layout={MainLayout} />} />
      <Route exact path="/design-company" render={(matchProps) => <WithLayout {...matchProps} component={DesignCompanyView} layout={MainLayout} />} />
      <Route exact path="/mobile-app" render={(matchProps) => <WithLayout {...matchProps} component={MobileAppView} layout={MainLayout} />} />
      <Route exact path="/job-listing" render={(matchProps) => <WithLayout {...matchProps} component={JobListingView} layout={MainLayout} />} />
      <Route exact path="/rental" render={(matchProps) => <WithLayout {...matchProps} component={RentalView} layout={MainLayout} />} />
      <Route exact path="/cloud-hosting" render={(matchProps) => <WithLayout {...matchProps} component={CloudHostingView} layout={MainLayout} />} />
      <Route exact path="/logistics" render={(matchProps) => <WithLayout {...matchProps} component={LogisticsView} layout={MainLayout} />} />
      <Route exact path="/e-commerce" render={(matchProps) => <WithLayout {...matchProps} component={EcommerceView} layout={MainLayout} />} />
      <Route exact path="/pricing" render={(matchProps) => <WithLayout {...matchProps} component={PricingView} layout={MainLayout} />} />
      <Route exact path="/about" render={(matchProps) => <WithLayout {...matchProps} component={AboutView} layout={MainLayout} />} />
      <Route exact path="/help-center" render={(matchProps) => <WithLayout {...matchProps} component={HelpCenterView} layout={MainLayout} />} />
      <Route exact path="/help-center-article" render={(matchProps) => <WithLayout {...matchProps} component={HelpCenterArticleView} layout={MainLayout} />} />
      <Route exact path="/portfolio-page" render={(matchProps) => <WithLayout {...matchProps} component={PortfolioPageView} layout={MainLayout} />} />
      <Route exact path="/portfolio-masonry" render={(matchProps) => <WithLayout {...matchProps} component={PortfolioMasonryView} layout={MainLayout} />} />
      <Route exact path="/portfolio-grid" render={(matchProps) => <WithLayout {...matchProps} component={PortfolioGridView} layout={MainLayout} />} />
      <Route exact path="/company-terms" render={(matchProps) => <WithLayout {...matchProps} component={CompanyTermsView} layout={MainLayout} />} />
      <Route exact path="/contact-sidebar-map" render={(matchProps) => <WithLayout {...matchProps} component={ContactPageSidebarMapView} layout={MainLayout} />} />
      <Route exact path="/contact-page-cover" render={(matchProps) => <WithLayout {...matchProps} component={ContactPageCoverView} layout={MainLayout} />} />
      <Route exact path="/about-side-cover" render={(matchProps) => <WithLayout {...matchProps} component={AboutSideCoverView} layout={MainLayout} />} />
      <Route exact path="/blog-search" render={(matchProps) => <WithLayout {...matchProps} component={BlogSearchView} layout={MainLayout} />} />
      <Route exact path="/blog-newsroom" render={(matchProps) => <WithLayout {...matchProps} component={BlogNewsroomView} layout={MainLayout} />} />
      <Route exact path="/blog-article" render={(matchProps) => <WithLayout {...matchProps} component={BlogArticleView} layout={MainLayout} />} />
      <Route exact path="/blog-reach-view" render={(matchProps) => <WithLayout {...matchProps} component={BlogReachViewView} layout={MainLayout} />} />
      <Route exact path="/password-reset-cover" render={(matchProps) => <WithLayout {...matchProps} component={PasswordResetCoverView} layout={MinimalLayout} />} />
      <Route exact path="/password-reset-simple" render={(matchProps) => <WithLayout {...matchProps} component={PasswordResetSimpleView} layout={MinimalLayout} />} />
      <Route exact path="/signin-simple" render={(matchProps) => <WithLayout {...matchProps} component={SigninSimpleView} layout={MinimalLayout} />} />
      <Route exact path="/signup-simple" render={(matchProps) => <WithLayout {...matchProps} component={SignupSimpleView} layout={MinimalLayout} />} />
      <Route exact path="/documentation" render={(matchProps) => <WithLayout {...matchProps} component={DocumentationView} layout={DocsLayout} />} />

      <Route path="" render={(matchProps) => <WithLayout {...matchProps} component={NotFoundView} layout={MinimalLayout} />} />
    </Switch>
  );
};

export default Routes;

const IS_LOGGED_IN = gql`
  query isLoggedIn {
    isLoggedIn @client
  }
`;

function GuestRoute({ children, ...rest }) {
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  if (!isLoggedIn) {
    return <Route {...rest} />;
  } else {
    return <Redirect to={{ pathname: '/' }} />;
  }
}

function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  if (isLoggedIn) {
    return <Route {...rest} />;
  } else {
    return <Redirect to={{ pathname: '/login' }} />;
  }
}
