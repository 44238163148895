export default {
  coucou: 'yo',
  fields: {
    email: 'Email',
    firstname: 'Prénom',
    lastname: 'Nom',
    address: 'Adresse',
    password: 'Mot de passe',
  },
  auth: {
    login: 'Se connecter',
    register: "S'inscrire",
    dont_have_account: "Vous n'avez pas de compte ?",
    remember_me: 'Se souvenir de moi',
    marked_fields_required: 'Les champs marqués du signe * sont obligatoires',
    messages: {
      login_success: 'Vous êtes bien connecté',
      login_bad_credentials: 'Votre email ou votre mot passe est incorrect',
      lougout_success: 'Vous êtes bien déconnecté',
    },
  },
};
