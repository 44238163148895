import { Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Section, SectionAlternate } from 'components/organisms';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getCompetitions } from 'services/Competition';
import { Application, Main, Newsletter } from './components';

const useStyles = makeStyles((theme) => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
}));

const Competition = () => {
  const classes = useStyles();
  let { slug } = useParams();

  const [competition, setCompetition] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const competitions = await getCompetitions({ slug });
      const competition = competitions[0];
      setCompetition(competition);
    }
    fetchData();
  }, []);

  return (
    competition && (
      <div>
        <Section className={classes.pagePaddingTop}>
          <Main competition={competition} />
        </Section>
        <Section>
          <Newsletter />
        </Section>
        <Divider />
      </div>
    )
  );
};

export default Competition;
