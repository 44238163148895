import React from 'react';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { TextField, InputAdornment, useMediaQuery, Grid, Typography, Button, NoSsr, colors } from '@mui/material';
import { Section, CardBase } from 'components/organisms';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  hero: {
    background: theme.palette.alternate.dark,
  },
  heroWrapper: {
    position: 'relative',
  },
  heroImageContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    height: '100%',
    width: '100%',
    backgroundSize: 'contain',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  heroGrid: {
    maxWidth: 926,
  },
  searchGrid: {
    zIndex: 3,
  },
  searchGridText: {},
  textField: {
    width: '100%',
  },
  searchButton: {
    width: '100%',
    height: '100%',
  },
  searchIcon: {
    color: colors.grey[600],
  },
  title: {
    fontWeight: 'bold',
  },
}));

const Hero = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <section className={classes.hero}>
      <Section className={classes.heroWrapper}>
        <Grid container spacing={isMd ? 6 : 4} className={classes.heroGrid}>
          <Grid item xs={12} className={classes.searchGridText}>
            <Typography variant="h2" color="textSecondary" className={classes.title}>
              {t('views.competitions.title')}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.searchGrid}>
            <CardBase variant="outlined" withShadow liftUp>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <TextField
                    label={t('views.competitions.competition')}
                    variant="outlined"
                    className={classes.textField}
                    size={isMd ? 'medium' : 'small'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <NoSsr>
                            <i className={clsx('fas fa-search', classes.searchIcon)} />
                          </NoSsr>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button className={classes.searchButton} size="large" variant="contained" color="primary">
                    {t('views.competitions.search')}
                  </Button>
                </Grid>
              </Grid>
            </CardBase>
          </Grid>
        </Grid>
        <div className={classes.heroImageContainer}></div>
      </Section>
    </section>
  );
};

export default Hero;
