import { Avatar, Button, Divider, Grid, List, ListItem, ListItemAvatar, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { DescriptionCta, SectionHeader } from 'components/molecules';
import { CardBase } from 'components/organisms';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .description-cta__button-group': {
      flexWrap: 'nowrap',
    },
  },
  title: {
    fontWeight: 'bold',
  },
  divider: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(5, 0),
    },
  },
  textWhite: {
    color: 'white',
  },
  cardHighlighted: {
    background: theme.palette.primary.dark,
  },
  checkBox: {
    background: 'transparent',
    borderRadius: 0,
  },
  list: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
}));

const Main = (props) => {
  const { competition, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <DescriptionCta
        title={competition.name}
        subtitle="San Francisco, CA · Full time"
        primaryCta={
          <Button variant="outlined" color="primary" size="large">
            Leaderboard
          </Button>
        }
        secondaryCta={
          <Button variant="contained" color="primary" size="large">
            S'inscrire ({competition.price} €)
          </Button>
        }
        align={'left'}
        titleProps={{
          variant: 'h3',
          className: classes.title,
          color: 'textPrimary',
        }}
        subtitleProps={{
          color: 'textPrimary',
        }}
      />
      <Divider className={classes.divider} />
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={8}>
          <SectionHeader
            title=""
            subtitle={competition.description}
            align="left"
            data-aos="fade-up"
            titleProps={{
              className: classes.title,
              color: 'textPrimary',
            }}
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />
          <SectionHeader
            title="Catégories"
            subtitle=""
            align="left"
            data-aos="fade-up"
            titleProps={{
              className: classes.title,
              color: 'textPrimary',
            }}
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
            disableGutter
          />
          <List className={classes.list}>
            {competition.categories.map((item, index) => (
              <ListItem disableGutters key={index} data-aos="fade-up">
                <ListItemAvatar>
                  <Avatar src="https://assets.maccarianagency.com/the-front/illustrations/check-icon-yellow.svg" className={classes.checkBox} />
                </ListItemAvatar>
                <Typography variant="body1" color="textPrimary">
                  {item.name}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={isMd ? 4 : 2} direction="column">
            <Grid item xs={12} data-aos="fade-up">
              <CardBase withShadow className={classes.cardHighlighted}>
                <SectionHeader
                  title="Participer à plusieurs !"
                  subtitle="Partager cette compétition à un ami pour participer ensemble !"
                  ctaGroup={[<Button variant="contained">Partager cette compétition</Button>]}
                  disableGutter
                  align="left"
                  titleProps={{
                    variant: 'h6',
                    className: classes.textWhite,
                  }}
                  subtitleProps={{
                    variant: 'body1',
                    className: classes.textWhite,
                  }}
                />
              </CardBase>
            </Grid>
            <Grid item xs={12} data-aos="fade-up">
              <CardBase withShadow>
                <SectionHeader
                  title="Plus de compétitions ?"
                  titleVariant="h6"
                  subtitle="Découvrez toutes les compétitions disponibles sur Wodnow"
                  ctaGroup={[
                    <Button variant="contained" color="primary">
                      Liste des compétitions
                    </Button>,
                  ]}
                  disableGutter
                  align="left"
                  subtitleProps={{
                    variant: 'body1',
                  }}
                />
              </CardBase>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Main;
