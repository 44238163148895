export default {
  general: {
    save: 'Enregistrer',
    messages: {
      save_success: 'Sauvegardé',
      save_error: 'Une erreur est survenue :/',
    },
  },
  validations: {
    invalid: 'Ce champ est invalide',
    required: 'Ce champ est obligatoire',
    stringMin: 'Ce champ doit faire au moins {{min}} caractères',
    stringMax: 'Ce champ doit faire au maximum {{max}} caractères',
  },
  fields: {
    email: 'Email',
    firstname: 'Prénom',
    lastname: 'Nom',
    address: 'Adresse',
    password: 'Mot de passe',
    zipcode: 'Code postal',
    city: 'Ville',
    phone: 'Téléphone',
    birthdate: 'Date de naissance',
  },
  auth: {
    login: 'Se connecter',
    register: "S'inscrire",
    dont_have_account: "Vous n'avez pas de compte ?",
    registerSubtitle: 'Pour participer à des dizaines de compétitions en ligne !',
    already_have_account: 'Vous avez déjà un compte ?',
    remember_me: 'Se souvenir de moi',
    marked_fields_required: 'Les champs marqués du signe * sont obligatoires',
    password_forgotten: 'Mot de passe oublié ?',
    reset_password: 'Changer mon mot de passe',
    messages: {
      login_success: 'Vous êtes connecté avec succès',
      register_success: 'Vous êtes inscris avec succès',
      login_bad_credentials: 'Votre email ou votre mot passe est incorrect',
      logout_success: 'Vous êtes bien déconnecté',
      register_error: 'Adresse email déjà utilisée',
    },
  },
  views: {
    account: {
      account_settings: 'Paramètres de compte',
      account_settings_subtitle: 'Changer les informations les paramètres de votre compte',
      information: 'Informations',
      general: 'Général',
      security: 'Sécurité',
      notifications: 'Notifications',
      billing: 'Facturation',
    },
    competitions: {
      title: 'Trouvez votre prochaine compétition',
      search: 'Rechercher',
      competition: 'Compétition',
      more: 'En savoir plus',
    },
  },
};
