import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getMe, updateUser } from 'services/User';
import { getError } from 'utils/Common';
import yup from 'yup.config';

const schema = yup.object().shape({
  firstname: yup.string().min(3).max(50).required(),
  lastname: yup.string().min(3).max(50).required(),
  email: yup.string().email().required(),
  phone: yup.string().required(),
  city: yup.string().required(),
  address: yup.string().required(),
  zipcode: yup.string().required(),
  birthdate: yup.date().max(moment()).required(),
});

const useStyles = makeStyles((theme) => ({
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
}));

const General = (props) => {
  const { t } = useTranslation();
  const { className, ...rest } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const user = await getMe();
      setUser(user);
    }
    fetchData();
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    let user = { firstname: data.firstname, lastname: data.lastname, email: data.email, birthdate: moment(data.birthdate).format('YYYY-MM-DD'), address: data.address, city: data.city, zipcode: data.zipcode, phone: data.phone };
    let update = await updateUser(user);
    if (update) {
      enqueueSnackbar(t('general.messages.save_success'), {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(t('general.messages.save_error'), {
        variant: 'error',
      });
    }
  };

  const hasError = (field) => {
    if (errors[field]) {
      return true;
    }
    return false;
  };
  return !user ? (
    'chargement...'
  ) : (
    <div className={className} {...rest}>
      <form onSubmit={handleSubmit(onSubmit)} method="post">
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12}>
            <Typography variant="h6" color="textPrimary">
              {t('views.account.information')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
              {t('fields.firstname')}
            </Typography>
            <Controller
              name="firstname"
              control={control}
              defaultValue={user.firstname}
              render={({ field }) => <TextField placeholder={t('fields.firstname')} variant="outlined" size="medium" fullWidth helperText={getError(errors.firstname)} error={hasError('firstname')} type="text" {...field} />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
              {t('fields.lastname')}
            </Typography>
            <Controller
              name="lastname"
              control={control}
              defaultValue={user.lastname}
              render={({ field }) => <TextField placeholder={t('fields.lastname')} variant="outlined" size="medium" fullWidth helperText={getError(errors.lastname)} error={hasError('lastname')} type="text" {...field} />}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
              {t('fields.email')}
            </Typography>
            <Controller
              name="email"
              control={control}
              defaultValue={user.email}
              render={({ field }) => <TextField placeholder={t('fields.email')} variant="outlined" size="medium" fullWidth helperText={getError(errors.email)} error={hasError('email')} type="email" {...field} />}
            />
          </Grid>
          <Divider />
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
              {t('fields.address')}
            </Typography>
            <Controller
              name="address"
              control={control}
              defaultValue={user.address}
              render={({ field }) => <TextField placeholder={t('fields.address')} variant="outlined" size="medium" fullWidth helperText={getError(errors.address)} error={hasError('address')} type="text" {...field} />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
              {t('fields.zipcode')}
            </Typography>
            <Controller
              name="zipcode"
              control={control}
              defaultValue={user.zipcode}
              render={({ field }) => <TextField placeholder={t('fields.zipcode')} variant="outlined" size="medium" fullWidth helperText={getError(errors.zipcode)} error={hasError('zipcode')} type="text" {...field} />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
              {t('fields.city')}
            </Typography>
            <Controller
              name="city"
              control={control}
              defaultValue={user.city}
              render={({ field }) => <TextField placeholder={t('fields.city')} variant="outlined" size="medium" fullWidth helperText={getError(errors.city)} error={hasError('city')} type="text" {...field} />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
              {t('fields.phone')}
            </Typography>
            <Controller
              name="phone"
              control={control}
              defaultValue={user.phone}
              render={({ field }) => <TextField placeholder={t('fields.phone')} variant="outlined" size="medium" fullWidth helperText={getError(errors.phone)} error={hasError('phone')} type="text" {...field} />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
              {t('fields.birthdate')}
            </Typography>
            <Controller
              name="birthdate"
              control={control}
              defaultValue={user.birthdate}
              render={({ field }) => (
                <TextField
                  placeholder={t('fields.birthdate')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  size="medium"
                  fullWidth
                  helperText={getError(errors.birthdate)}
                  error={hasError('birthdate')}
                  type="date"
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item container justifyContent="flex-start" xs={12}>
            <Button variant="contained" type="submit" color="primary" size="large">
              {t('general.save')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default General;
