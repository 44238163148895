import { ApolloProvider } from '@apollo/client';
import 'aos/dist/aos.css';
import 'assets/css/index.css';
import { createBrowserHistory } from 'history';
import 'i18n.config';
import 'leaflet/dist/leaflet.css';
import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { Router } from 'react-router-dom';
import 'swiper/css/swiper.min.css';
import client from './Apollo';
import Routes from './Routes';

const browserHistory = createBrowserHistory();

const App = () => {
  return (
    <Router history={browserHistory}>
      <SnackbarProvider maxSnack={3}>
        <ApolloProvider client={client}>
          <Suspense fallback="Chargement...">
            <Routes />
          </Suspense>
        </ApolloProvider>
      </SnackbarProvider>
    </Router>
  );
};

export default App;
