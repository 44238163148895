import { Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Section } from 'components/organisms';
import React from 'react';
import { CompetitionsList, Hero } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sectionAlternate: {
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 400px, ${theme.palette.primary.dark} 0%)`,
  },
}));

const Competitions = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hero />
      <Divider />
      <Section>
        <CompetitionsList />
      </Section>
    </div>
  );
};

export default Competitions;
