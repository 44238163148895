import { gql } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import apolloClient from 'Apollo';
import { isLoggedInVar } from 'cache';
import { LearnMoreLink } from 'components/atoms';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getError } from 'utils/Common';
import yup from 'yup.config';

const LOGIN_MUTATION = gql`
  mutation($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      jwt
      user {
        id
        firstname
        lastname
        username
        email
      }
    }
  }
`;

const schema = yup.object().shape({
  email: yup.string().email().max(300).required(),
  password: yup.string().min(6).required(),
});

const Form = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  let history = useHistory();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const loginUser = async ({ identifier, password, rememberMe }) => {
    try {
      const result = await apolloClient.mutate({
        variables: { identifier, password },
        mutation: LOGIN_MUTATION,
      });

      if (result.data !== null) {
        const { user } = result.data.login;
        const token = result.data.login.jwt;
        localStorage.setItem('token', token);
        localStorage.setItem('id', user.id);

        if (rememberMe === true) {
          const cookies = new Cookies();
          cookies.set('token', token, { path: '/' });
          cookies.set('id', user.id, { path: '/' });
        }

        isLoggedInVar(true);

        return user;
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const onSubmit = async (data) => {
    let user = {
      identifier: data.email,
      password: data.password,
      rememberMe: data.rememberMe,
    };
    user = await loginUser(user);
    if (user) {
      enqueueSnackbar(t('auth.messages.login_success'), {
        variant: 'success',
      });
      isLoggedInVar(true);
      history.push('/');
    } else {
      enqueueSnackbar(t('auth.messages.login_bad_credentials'), {
        variant: 'error',
      });
    }
  };

  const hasError = (field) => {
    if (errors[field]) {
      return true;
    }
    return false;
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)} method="post">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField placeholder={t('fields.email')} label={`${t('fields.email')} *`} variant="outlined" size="medium" fullWidth helperText={getError(errors.email)} error={hasError('email')} type="email" {...field} />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField placeholder={t('fields.password')} label={`${t('fields.password')} *`} variant="outlined" size="medium" fullWidth helperText={getError(errors.password)} error={hasError('password')} type="password" {...field} />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="rememberMe"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  className={classes.formControlLabel}
                  control={<Checkbox color="primary" onChange={(e) => field.onChange(e.target.checked)} checked={field.value} />}
                  label={<Typography variant="body1">{t('auth.remember_me')}</Typography>}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <i>
              <Typography variant="subtitle2">{t('auth.marked_fields_required')}</Typography>
            </i>
          </Grid>
          <Grid item xs={12}>
            <Button size="large" variant="contained" type="submit" color="primary" fullWidth>
              {t('auth.login')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textSecondary" align="center">
              {t('auth.password_forgotten')} <LearnMoreLink title={t('auth.reset_password')} href="/password-reset-cover" />
            </Typography>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  formControlLabel: {
    marginRight: 0,
  },
}));

export default Form;
