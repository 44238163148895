import { gql } from '@apollo/client';
import { isLoggedInVar } from 'cache';
import Cookies from 'universal-cookie';
import apolloClient from '../Apollo';

const GET_USER_QUERY = gql`
  query {
    self {
      id
      firstname
      lastname
      email
      phone
      birthdate
      city
      address
      zipcode
    }
  }
`;

const UPDATE_USER_MUTATION = gql`
  mutation($id: ID!, $firstname: String!, $lastname: String!, $email: String!, $phone: String!, $birthdate: Date!, $address: String!, $city: String!, $zipcode: String!) {
    updateUser(input: { where: { id: $id }, data: { firstname: $firstname, lastname: $lastname, email: $email, phone: $phone, birthdate: $birthdate, address: $address, city: $city, zipcode: $zipcode } }) {
      user {
        id
        firstname
        lastname
        email
        phone
        birthdate
        city
        address
        zipcode
      }
    }
  }
`;

export async function logout() {
  const cookies = new Cookies();
  cookies.remove('token');
  cookies.remove('id');
  localStorage.removeItem('token');
  localStorage.removeItem('id');
  apolloClient.resetStore();
  isLoggedInVar(false);
}

export function getLocalData() {
  const cookies = new Cookies();
  let token = cookies.get('token') || localStorage.getItem('token') || null;
  let id = cookies.get('id') || localStorage.getItem('id') || null;

  return { token, id };
}

export function isLogged() {
  const cookies = new Cookies();
  let token = cookies.get('token') || localStorage.getItem('token') || null;

  if (token) return true;
  return false;
}

export async function updateUser(user) {
  try {
    const result = await apolloClient.mutate({
      variables: { id: (await getMe()).id, ...user },
      mutation: UPDATE_USER_MUTATION,
    });

    if (result.data !== null) {
      const { user: userUpdated } = result.data.updateUser;

      return userUpdated;
    }
  } catch (error) {
    console.log('error', error);
  }
}

export async function getMe() {
  try {
    const result = await apolloClient.query({
      query: GET_USER_QUERY,
    });

    if (result.data !== null) {
      const userInfo = result.data.self;

      return userInfo;
    }
  } catch (error) {
    console.log('error', error);
  }
}
