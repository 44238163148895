import * as yup from 'yup';
import i18n from 'i18n.config';

yup.setLocale({
  mixed: {
    default: {
      key: i18n.t('validations.invalid'),
    },
    required: ({ path }) => ({
      key: i18n.t('validations.required', { path }),
    }),
    notType: ({ type }) => ({
      key: 'validations.invalidType',
      values: { type },
    }),
  },
  string: {
    email: {
      key: 'validations.email',
    },
    min: ({ min }) => ({
      key: i18n.t('validations.stringMin', { min }),
    }),
    max: ({ min }) => ({
      key: i18n.t('validations.stringMax', { max }),
    }),
  },
  number: {},
  boolean: {},
});

export default yup;
