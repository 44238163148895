import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Hidden, IconButton, List, ListItem, ListItemIcon, Popover, Toolbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import logoDark from 'assets/images/logo-dark.svg';
import logoLight from 'assets/images/logo-light.svg';
import clsx from 'clsx';
import { DarkModeToggler, Image } from 'components/atoms';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { isLogged, logout } from 'services/User';
import { gql, useQuery } from '@apollo/client';
import { getLocalData } from 'services/User';

const Topbar = ({ themeMode, themeToggler, onSidebarOpen, pages, className, ...rest }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);

  const handleClick = (event, popoverId) => {
    setAnchorEl(event.target);
    setOpenedPopoverId(popoverId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  return (
    <Toolbar disableGutters className={classes.toolbar} {...rest}>
      <div className={classes.logoContainer}>
        <Link to="/" title="wodnow">
          <Image className={classes.logoImage} src={themeMode === 'light' ? logoLight : logoDark} alt="wodnow" lazy={false} />
        </Link>
      </div>
      <div className={classes.flexGrow} />
      <Hidden mdDown>
        <List disablePadding className={classes.navigationContainer}>
          <ListItem>
            <Link variant="body1" to={'/competitions'} style={{ width: 'max-content' }} className={clsx(classes.listItem, openedPopoverId === 'competitions' ? classes.listItemActive : '')} color="textSecondary" onClick={handleClose}>
              Liste des compétitions
            </Link>
          </ListItem>
          <ListItem>
            <Link variant="body1" to={'#'} style={{ width: 'max-content' }} className={clsx(classes.listItem, openedPopoverId === 'competitions' ? classes.listItemActive : '')} color="textSecondary" onClick={handleClose}>
              Mes compétitions
            </Link>
          </ListItem>

          {isLogged() ? (
            <>
              <ListItem aria-describedby={'account'} onClick={(e) => handleClick(e, 'account')} className={clsx(classes.listItem, openedPopoverId === 'account' ? classes.listItemActive : '')}>
                <Typography variant="body1" color="textPrimary" className={clsx(classes.listItemText, 'menu-item')}>
                  Mon compte
                </Typography>
                <ListItemIcon className={classes.listItemIcon}>
                  <ExpandMoreIcon className={openedPopoverId === 'account' ? classes.expandOpen : ''} fontSize="small" />
                </ListItemIcon>
              </ListItem>
              <Popover
                elevation={1}
                id={'account'}
                open={openedPopoverId === 'account'}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                classes={{ paper: classes.popover }}
              >
                <div>
                  <div className={classes.menu}>
                    <List disablePadding>
                      <ListItem disableGutters className={classes.menuGroupItem}>
                        <Link to={`/athlete/${getLocalData().id}`} className={clsx(classes.navLink, 'submenu-item')} color="textSecondary" onClick={handleClose}>
                          Profil public
                        </Link>
                      </ListItem>
                      <ListItem disableGutters className={classes.menuGroupItem}>
                        <Link to={'/account'} className={clsx(classes.navLink, 'submenu-item')} color="textSecondary" onClick={handleClose}>
                          Mon compte
                        </Link>
                      </ListItem>
                      <ListItem disableGutters className={classes.menuGroupItem}>
                        <span
                          className={clsx(classes.navLink, 'submenu-item')}
                          color="textSecondary"
                          onClick={() => {
                            logout();
                            history.push('/');

                            enqueueSnackbar(t('auth.messages.logout_success'), {
                              variant: 'success',
                            });
                          }}
                        >
                          Se déconnecter
                        </span>
                      </ListItem>
                    </List>
                  </div>
                </div>
              </Popover>
            </>
          ) : null}
          <ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
            <DarkModeToggler themeMode={themeMode} onClick={() => themeToggler()} />
          </ListItem>
          {!isLogged() ? (
            <>
              <ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
                <Link to="/login">
                  <Button variant="outlined" style={{ whiteSpace: 'nowrap' }}>
                    Se connecter
                  </Button>
                </Link>
              </ListItem>
              <ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
                <Link to="/register">
                  <Button variant="contained" color="primary" className={classes.listItemButton}>
                    S'inscrire
                  </Button>
                </Link>
              </ListItem>
            </>
          ) : null}
        </List>
      </Hidden>
      <Hidden mdUp>
        <DarkModeToggler themeMode={themeMode} onClick={() => themeToggler()} />
        <IconButton className={classes.iconButton} onClick={onSidebarOpen} aria-label="Menu" size="large">
          <MenuIcon />
        </IconButton>
      </Hidden>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: 'flex',

    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolbar: {
    zIndex: 999,
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
  navLink: {
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  listItem: {
    cursor: 'pointer',
    '&:hover > .menu-item, &:hover svg': {
      color: theme.palette.primary.dark,
    },
    '&.menu-item--no-dropdown': {
      paddingRight: 0,
    },
  },
  listItemActive: {
    '&> .menu-item': {
      color: theme.palette.primary.dark,
    },
  },
  listItemText: {
    flex: '0 0 auto',
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
  listItemButton: {
    whiteSpace: 'nowrap',
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  popover: {
    padding: theme.spacing(4),
    border: theme.spacing(2),
    boxShadow: '0 0.5rem 2rem 2px rgba(116, 123, 144, 0.09)',
    minWidth: 100,
    marginTop: theme.spacing(2),
  },
  iconButton: {
    marginLeft: theme.spacing(2),
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    color: theme.palette.primary.dark,
  },
  logoContainer: {
    width: 100,
    height: 28,
    [theme.breakpoints.up('md')]: {
      width: 120,
      height: 32,
    },
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItem: {
    marginRight: theme.spacing(5),
    '&:last-child': {
      marginRight: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
  },
}));

export default Topbar;
